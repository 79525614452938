<template>
    <div class="current-offers-container" v-if="isAdmin">
      <div class="offer-group" v-if="offer.content">
        <h3>Code: {{ offer.content.id }}</h3>
          <div class="offer-item">
              <div class="offer-thumbs">
                  <div class="thumb-item">
                      <div class="thumb-photo" :src="photo.image" :key="photoIndex" v-for="photo, photoIndex in offer.content.skuList">
                          <img :src="photo.image" />
                      </div>
                  </div>
  
              </div>
              <div class="offer-info">
                <div>Collection: {{offer.name}} ({{offer.content.skuList.length}} designs)</div>
                <div>{{offer.description}}</div>
                <div>Expired: {{offer.expiredBy}}</div>
                <div>MOQ: {{offer.minimumOrder}} pcs</div>
                <div>Recommended Retail Price: {{offer.rrp}} AUD/pcs</div>
                <div>Discount rate: {{ discountRate * 100 }}%</div>
                <div v-if="offer.rrp">Price: <strong>{{getDiscount(discountRate, offer.rrp)}} AUD/pcs + GST</strong></div>
                <div>Note: {{ offer.note }}</div>
              </div>
              <div class="order-info" v-if="offer.rrp">
                Order all collection ({{  offer.content.skuList.length }}) for <strong>${{ orderAll() }}</strong> or individually from the link beflow<br/><br/>

                Order link: <strong>https://customers.colordemy.com</strong><br/>
                Username: <strong>guest2023</strong><br/>
                Password: <strong>guest2023</strong>
              </div>
              <div class="order-info">
                All inquiries: Si Nguyen - si.nguyen@colordemy.com<br/>
                Goji Interactive Pty Ltd<br/>
                ABN 75 604 655 825<br/>
                Address: Level 2/11 York St, Sydney NSW 2000<br/>
                Ph: 042 0605 485 - Website: www.colordemy.com<br/>
                BSB: 062-134  Account: 1040 3514


              </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'

  import { getDiscount } from '../utils/calc'
  
  export default {
    name: 'AdViewOffer',
    props: {
      msg: String
    },
    data() {
      return {
        offer: {}
      }
    },
    computed: {
      ...mapState([
        'orderList',
        'offerData',
        'role',
        'user'
      ]),
      isAdmin() {
          return this.role === 'admin'
      },
      discountRate() {
        return (this.user.discountRate * 100).toFixed(0)
      }
    },
    watch: {

        offerData() {
            const offerId = this.$route.params.id
            this.offerId = offerId
            this.offer = this.offerData.filter(item => item.offerId === offerId)[0]
        }
    },
    mounted() {
          this.$store.dispatch('getOfferData')
    },
    methods: {
        getDiscount,
        orderAll() {
            const totalDesigns = this.offer.content.skuList.length
            const price = getDiscount(this.offer.discountRate, this.offer.rrp)

            return totalDesigns * price
        },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .current-offers-container {
    width: 600px;
    margin: auto;
  }
  .offer-group {
      display: flex;
      flex-direction: column;
      padding: 0;
      width: 100%;
  
      .offer-item {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 0;
          justify-content: left;
          margin: 10px 0;
          border-bottom: 1px solid #DDD;
      }
  }
  
  
  .thumb-item {
      width: 100%;
      margin: 0;
  
      @media only screen and (min-width: 600px)  {
          width: 400px;
      }
  
      .thumb-photo {
          width: 100px;
          float: left;
  
          img {
              width: 100%;
          }
      }
  }
  
  .offer-info {
      display: flex;
      justify-content: left;
      flex-direction: column;
      flex: 0 0 100%;
      padding: 0;
      margin: 0;
      width: 100%;
  
      div {
          text-align: left;
          padding: 2px 0 0 0;
      }
  
      h3 {
          text-align: left;
      }
  }
  
  .button-group {
      display: flex;
      flex-direction: row;
      margin: auto;
  
      button {
          margin: 10px;
      }
  }

  .order-info {
    justify-content: left;
    text-align: left;
    margin: 20px 0 0 0;
    border-top: 1px solid #DDD;
    padding: 10px 0 10px 0;
  }
  
  </style>
  