<template>
  <div class="page-container">
    <MenuNav />
    <CurrentOffersPage />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CurrentOffersPage from '../components/CurrentOffers.vue'
import MenuNav from '../components/Menu.vue'

export default {
  name: 'HomePage',
  data() {
    return {

    }
  },
  components: {
    CurrentOffersPage,
    MenuNav
  },
  computed: {
    ...mapState([
      'count',
      'orderList'
    ])
  },
  methods: {
    
  }
}
</script>

<style>
.page-container {
    max-width: 1280px;
    margin: auto;
    padding: 20px;
}

#order-page-api {
  display: none;
}

.flex-grow {
  flex-grow: 1;
}
</style>