export const state = () => {
    return {
        orderList: [],
        skuData: {},
        count: 0,
        offerData: [],
        isLoggedIn: false,
        accessToken: '',
        role: '',
        isAuthError: false,
        allOrders: [],
        message: {},
        user: {},
        currentOrder: {}
    }
}