<template>
    <div>
        <h1>User Settings</h1>
        Hi {{ user.firstName }}

        <div><el-button @click="handleLogout">Logout</el-button></div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UserPage',
  components: {
  },
  computed: {
    ...mapState([
      'count',
      'orderList',
      'user',
      'isLoggedIn'
    ])
  },
  mounted () {

  },
  methods: {
    handleLogout() {
        this.$store.dispatch('FIREBASE_SIGN_OUT')
        
        this.$router.push({ path: '/'})
    }
  }
}
</script>