<template>
    <div class="cart-page">
        <div class="sticky-calculator" v-if="parseInt(calcTotal()) > 0 && !showRegisterForm && showCart">
            <h1>Cart</h1>
            <h3 v-if="isEdit">Edit your order</h3>
            <h4>Total ({{ orderList.length }}) designs</h4>
            <div class="sticky-cart">
                <div class="order-item" :key="index" v-for="item, index in orderList">
                    <div class="thumbnail">
                        <img :src="item.image" width="50">
                    </div>
                    <div class="info">
                        Price: ${{item.price}}<br/>
                        Offer: {{item.offerId}}<br/>
                        SKU: {{item.sku}}<br/>
                        <button size="mini" @click="removeCartItem(item)">Remove</button>
                    </div>
                    <div class="button-group">
                        <button @click="updateItem(item, 'remove')">-</button>
                        <span class="order-page-quantity">{{item.quantity}}</span>
                        <button @click="updateItem(item, 'add')">+</button>
                    </div>
                    <div class="price">{{calcOrderItem(item)}}</div>
                </div>

                <div class="total-wrapper">
                    <div class="total-order">Subtotal: ${{calcSubTotal()}}</div>
                    <div class="total-order">Tax: ${{calcTax()}}</div>
                    <div class="total-order">Freight: 0</div>
                    <div class="total-order">Total: ${{calcTotal()}}</div>
                </div>
            </div>
            <el-popconfirm width="220" confirm-button-text="OK" cancel-button-text="No, Thanks"
                @confirm="handleClearCart()" icon-color="#626AEF" title="Are you sure to reset this?">
                <template #reference>
                  <el-button type="default" size="large">Reset</el-button>
                </template>
              </el-popconfirm>
            
            <el-button type="success" size="large" @click="this.showRegisterForm = true">NEXT</el-button>
        </div>
        <div v-if="showRegisterForm && parseInt(calcTotal()) > 0">
          <el-button @click="handleShowCart">Back</el-button><br/><br/>
          <FormRegister :currentOrder="currentOrder" />
        </div>
        <div v-if="showSuccess">
          <el-alert title="Thank you for your order request! One of our team members will get back to you shortly." type="success" />
        </div>
        <div v-if="!parseInt(calcTotal()) > 0 && !showSuccess">
          <h4>Cart is empty, please add more products into cart.</h4>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FormRegister from '../components/FormRegister.vue'

export default {
  name: 'App',
  data() {
    return {
        rrp: 0,
        showRegisterForm: false,
        showSuccess: false,
        showCart: true
    }
  },
  components: {
    FormRegister
  },
  computed: {
    ...mapState([
      'orderList',
      'offerData',
      'currentOrder',
      'user'
    ]),
    discountRate() {
      return this.user.discountRate
    },
    isEdit() {
      return this.currentOrder && this.currentOrder.id
    }
  },
  watch: {
    orderList() {
      console.log('updated', this.orderList)
    }
  },
  methods: {
    calcOrderItem(item) {
      const calc = (item.price * item.quantity).toFixed(2)

      return `$${calc}`
    },
    calcSubTotal() {
      const subtotal = this.orderList.reduce((acc, item) => {
        const calcItem = item.quantity * item.price

        acc += calcItem

        return acc
      }, 0)

      const fixedSubTotal = subtotal.toFixed(2)

      return fixedSubTotal
    },
    calcTax() {
      return (this.calcSubTotal() * 0.1).toFixed(2)
    },
    calcTotal() {
      const getTotal = parseFloat(this.calcSubTotal()) + parseFloat(this.calcTax())

      const cleanGetTotal = getTotal

      return cleanGetTotal.toFixed(2)
    },
    updateItem (selectedItem, type) {
        const quantity = selectedItem.quantity
        const sku = selectedItem.sku
        const findSku = this.orderList.filter(item => item.sku === selectedItem.sku)[0]

        const newQuantity = type === 'add' ? quantity + 1 : quantity - 1

        const addedItem = Object.assign({}, findSku, { quantity: newQuantity })
        const newOrderList = this.orderList.filter(item => item.sku !== sku)

        const updatedOrderList = newQuantity > 0 ? [...newOrderList, addedItem] : newOrderList

        this.$store.dispatch('updateCart', { orderList: updatedOrderList })
    },
    removeCartItem(selectedItem) {
      const sku = selectedItem.sku

      const newOrderList = this.orderList.filter(item => item.sku !== sku)
      this.$store.dispatch('updateCart', { orderList: newOrderList })
    },
    handleShowCart () {
      this.showRegisterForm = false
    },
    handleShowSuccess() {
      this.showRegisterForm = false
      this.showSuccess = true
      this.showCart = false
    },
    handleClearCart() {
      this.$store.dispatch('clearCart')
    }
  }
}
</script>

<style scoped lang="scss">
.cart-page {
  max-width: 100%;
  margin: auto;
  padding: 10px 0;

  .thumbnail {
    width: 15%;
  }

  .info {
    width: 40%;
  }

  .price {
    width: 20%;
  }

  .button-group {
    width: 25%;
  }

  @media only screen and (min-width: 600px)  {
    max-width: 600px;
  }
}
.sticky-cart {
  display: flex;
  flex-flow: column;
  text-align: left;
}

.order-item {
  padding: 3px;
  font-size: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #DDD;
}

.total-order {
  font-size: 12px;
  padding: 0 10px;
  text-align: right;
  margin: 5px 0;
}

.total-wrapper {
  border: 0;
  margin: 5px 0;
  font-size: 12px;
}

.button-group {
  margin: 3px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  margin: auto;

  button {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  span {
    display: block;
    margin: 0 5px;
  }
}
</style>
