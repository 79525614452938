import offers from '../assets/data/offers.json'
import march01 from '../assets/data/march01.json'
import march02 from '../assets/data/march02.json'
import march03 from '../assets/data/march03.json'
import march04 from '../assets/data/march04.json'
import march05 from '../assets/data/march05.json'
import march06 from '../assets/data/march06.json'
import march07 from '../assets/data/march07.json'
import march08 from '../assets/data/march08.json'
import march09 from '../assets/data/march09.json'

import actionsOrders from './actions_orders'
import auth from './auth'

const increaseCount = ({ commit }, data) => {
    commit('increment', data)
}

const updateCart = ({ commit }, data) => {
    commit('updateCart', data)
}

const clearCart = ({ commit }) => {
    commit('receiveClearCart')
}

const updateSkuList = ({ commit }, data) => {
    commit('updateSkuList', data)
}

const updateCartForEdit = ({ commit }, data) => {
    commit('receiveUpdateCartForEdit', data)
}

const getOfferData = ({ commit }) => {
    const allOffers = [
        march01,
        march02,
        march03,
        march04,
        march05,
        march06,
        march07,
        march08,
        march09
    ]

    const getKeyOut = allOffers.reduce(
        (acc, item) => {
            acc[item.id] = item

            return acc
        }, {})

    const data = offers.map(item => {
        return {
            ...item,
            content: getKeyOut[item.offerId] || {}
        }
    })

    commit('updateOfferData', data)
}

export const actions = {
    updateCart,
    clearCart,
    updateSkuList,
    increaseCount,
    getOfferData,
    updateCartForEdit,
    ...actionsOrders,
    ...auth
}