<template>
    <div class="page-container" v-if="isAdmin">
        <h3>Orders</h3>
        <el-button size="small" @click="handleFilter('all')">All</el-button>
        <el-button size="small" @click="handleFilter('open')">Open</el-button>
        <el-button size="small" @click="handleFilter('unpaid')">Unpaid</el-button>
        <el-button size="small" @click="handleFilter('paid')">Paid</el-button>
        <el-button size="small" @click="handleFilter('archived')">Archived</el-button>
        <el-table :data="filteredOrders" style="width: 100%">
            <el-table-column label="Info" width="380">
                <template #default="scope">
                    <div class="small-text">
                    Invoice: {{  scope.row.invoiceNumber }}<br/>
                    Name: <strong>{{ scope.row.firstName }} {{ scope.row.lastName }}</strong><br/>
                    Company: {{ scope.row.company }}<br/>
                    id: {{  scope.row.id }}<br/>

                    Order Date: {{ getDate(scope.row.dateCreated) }}<br/>
                    Modified Date: {{ getDate(scope.row.modifiedDate) }}<br/>

                    Delivery Address: {{ scope.row.deliveryAddress || scope.row.address }}<br/>
                    Billing Address: {{ scope.row.billingAddress || scope.row.address }}<br/>

                    Email: {{ scope.row.email }}<br/>
                    </div>
                    <div v-if="scope.row.status !== 'archived'">
                        <el-popconfirm width="220" confirm-button-text="OK" cancel-button-text="No, Thanks"
                            @confirm="removeOrder(scope.row.id)" icon-color="#626AEF" title="Are you sure to delete this?">
                            <template #reference>
                                <el-button type="danger" size="small">Delete</el-button>
                            </template>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Amount" width="180">
                <template #default="scope">
                    {{ getTotalInfo(scope.row).total }}
                </template>
            </el-table-column>
            <el-table-column label="Status" width="80">
                <template #default="scope">
                    {{ scope.row.status }}
                </template>
            </el-table-column>
            <el-table-column align="right">
                <template #header>
                    <el-input v-model="search" size="small" placeholder="Type to search" />
                </template>
                <template #default="scope">
                    <el-button size="small" @click="handleEdit(scope.row)">Edit</el-button>
                    <el-button size="small" @click="handleInvoicePreview(scope.row)">Preview</el-button>
                    <el-button size="small" @click="handleTaxInvoice(scope.row.id)">Tax Invoice</el-button>
                    <el-button size="small" @click="handleCloneInvoice(scope.row)">Clone</el-button>

                </template>
            </el-table-column>
        </el-table>
        <el-dialog v-model="showDialog" :show-close="false">
            <template #header="{ titleId, titleClass }">
                <div class="my-header">
                    <h4 :id="titleId" :class="titleClass"></h4>
                    <el-button type="danger" @click="handleCloseDialog">
                        <el-icon class="el-icon--left">
                            <CircleCloseFilled />
                        </el-icon>
                        Close
                    </el-button>
                </div>
                <div class="dialog-content">
                    <TaxInvoice :currentOrder="currentOrder" v-if="showInvoice" />
                    <FormRegister :currentOrder="currentOrder" v-if="showEditForm"/>
                </div>
            </template>
        </el-dialog>
</div>
</template>
  
<script>
import { mapState } from 'vuex'
import { ElNotification } from 'element-plus'
import { CircleCloseFilled } from '@element-plus/icons-vue'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'

import FormRegister from '../components/FormRegister.vue'
import TaxInvoice from '../components/TaxInvoice.vue'

import { sendInvoicePdf } from '../utils/mail'
import { getTotalCart, calcOrderItem, getTotalInfo } from '@/utils/calc'
import { invoiceNumber } from '../utils/common'

export default {
    name: 'AdOrderRequestsPage',
    components: {
        CircleCloseFilled,
        FormRegister,
        TaxInvoice
    },
    data() {
        return {
            showDialog: false,
            showInvoice: false,
            showEditForm: false,
            currentOrder: {},
            filteredOrders: []
        }
    },
    computed: {
        ...mapState([
            'allOrders',
            'message',
            'role'
        ]),
        offerId() {
            return this.$route.params.id
        },
        isAdmin() {
            return this.role === 'admin'
        }
    },
    watch: {
        message() {
            this.showMessage(this.message.content)
            this.refetchOrders()
        },
        allOrders() {
            this.filteredOrders = this.allOrders
        }
    },
    mounted() {
        this.$store.dispatch('FIREBASE_GET_ALL_ORDERS')
    },
    methods: {
        calcOrderItem,
        getTotalCart,
        getTotalInfo,
        calcDiscount(discountRate) {
            return `-${discountRate * 100}%`
        },
        generateInvoice() {
            const doc = new jsPDF();
            doc.text(20, 20, 'Hello world!');
            doc.text(20, 30, 'This is client-side Javascript, pumping out a PDF.');
            autoTable(doc, { html: '#invoice', margin: { top: 50, fontSize: 8 } })

            const file = doc.output('datauristring').split('base64,')[1];
            return file
        },
        sendPdf() {
            const sendTo = {
                subject: 'Send PDF Mail',
                email: 'nguyensi.art@gmail.com',
                fileName: 'invoice.pdf'
            }

            const bodyHtml = `<h3>PDF mail</h3>`
            const pdfOutput = this.generateInvoice()

            sendInvoicePdf(sendTo, bodyHtml, pdfOutput)
        },
        removeOrder(id) {
            this.$store.dispatch('FIREBASE_REMOVE_ORDER', { documentId: id })
        },
        showMessage(content) {
            ElNotification({
                title: 'Success',
                message: content,
                type: 'success',
            })

            this.handleCloseDialog()
        },
        getOrderInfo(orderList) {
            const orderListJson = JSON.parse(orderList)
            return {
                totalCart: getTotalCart(orderListJson)
            }
        },
        getClientInfo() {

        },
        handleInvoicePreview(currentOrder) {
            this.showDialog = true
            this.showEditForm = false
            this.showInvoice = true
            this.currentOrder = Object.assign({}, currentOrder, {
                orderList: currentOrder.orderList
            })
        },
        getDate(dateCreated) {
            return typeof dateCreated?.toDate === 'function' ? dateCreated.toDate().toDateString() : ''
        },
        handleEdit(currentOrder) {
            this.currentOrder = Object.assign({}, currentOrder, {
                orderList: JSON.parse(currentOrder.orderList)
            })

            this.$store.dispatch('updateCartForEdit', currentOrder)
            this.showDialog = true
            this.showEditForm = true
            this.showInvoice = false
        },
        refetchOrders() {
            this.$store.dispatch('FIREBASE_GET_ALL_ORDERS')
        },
        handleTaxInvoice(docId) {
            this.$router.push(`/order-invoice/${docId}`)
        },
        handleCloneInvoice(currentOrder) {
            this.currentOrder = Object.assign({}, currentOrder, {
                orderList: currentOrder.orderList,
                invoiceNumber: invoiceNumber(),
                dateCreated: new Date(),
                modifiedDate: new Date(),
                status: "open"
            })

            this.$store.dispatch('FIREBASE_CREATE_ORDERS', this.currentOrder)
            this.$store.dispatch('FIREBASE_GET_ALL_ORDERS')
        },
        handleFilter(type) {
            if (type === 'all') {
                this.filteredOrders = this.allOrders
            } else {
                this.filteredOrders = this.allOrders.filter(item => item.status === type)
            }
        },
        handleCloseDialog() {
            if (!this.showEditForm) {
                this.$store.dispatch('clearCart')
            }

            this.showDialog = false
            this.showEditForm = false
            this.showInvoice = false
        }
    }
}
</script>

<style lang="scss" scoped>
.my-header {
    display: flex;
    justify-content: space-between;
}

.small-text {
    font-size: 12px;
    line-height: 12px;
}
</style>
