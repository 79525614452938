<template>
  <div class="main-page">
    <div v-if="!isLoggedIn" class="login-form-container">
      <div class="login-form">
        <h3>Colordemy - Shop wholesale</h3>
        <el-alert title="User or Password invalid!" type="error" effect="dark" v-if="isAuthError || this.showError"></el-alert>
        <br/>
        <el-form ref="form" label-width="120px">
          <el-form-item label="Email/Username">
            <el-input v-model="email" />
          </el-form-item>
          <el-form-item label="Password">
            <el-input v-model="password" type="password" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">
              Log in
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="isLoggedIn">
      <el-menu :default-active="activeIndex2" class="el-menu-header" mode="horizontal" background-color="#545c64"
        text-color="#fff" active-text-color="#ffd04b" :ellipsis="false" image.pngimage.png @select="handleSelect">
        <el-menu-item index="1"><router-link to="/">Home Page</router-link></el-menu-item>
        <el-sub-menu index="2" v-if="role === 'admin'">
          <template #title>Admin</template>
          <el-menu-item index="2-1" @click="this.$router.push({ path: '/order-requests'})">Order Requests</el-menu-item>
          <el-menu-item index="2-3" @click="this.$router.push({ path: '/user'})">Settings</el-menu-item>
          <el-menu-item index="2-4" @click="this.$router.push({ path: '/members'})">Members</el-menu-item>
        </el-sub-menu>
        <div class="flex-grow" />
        <el-menu-item index="5">
          <router-link to="/cart">
            Cart (${{ getTotalCart(orderList) }})
          </router-link>
        </el-menu-item>
        <el-menu-item index="6" v-if="role !== 'admin'">
          <router-link to="/user">
            Settings
          </router-link>
        </el-menu-item>
      </el-menu>
      <router-view></router-view>
    </div>
    <footer>
      <p>
        Goji Interative Pty Ltd (c) 2023
      </p>
    </footer>
  </div>

</template>

<script>
import { mapState } from 'vuex'

import { getTotalCart } from '../utils/calc'

export default {
  name: 'App',
  data() {
    return {
      email: '',
      password: '',
      showError: false
    }
  },
  components: {},
  computed: {
    ...mapState([
      'count',
      'orderList',
      'skuData',
      'isLoggedIn',
      'isAuthError',
      'role'
    ])
  },
  methods: {
    getTotalCart,
    onSubmit() {
      const isGuest = this.email === 'guest2023'

      const email = isGuest ? 'guest2023@colordemy.com' : this.email

      const data = {
        email: email,
        password: this.password
      }

      if (email.length && this.password.length) {
        this.showError = false
        this.$store.dispatch('FIREBASE_AUTH', data)
      } else {
        this.showError = true
      }
    }
  }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');

body {
  padding: 0;
  margin: 0;
}

#order-page-app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

ul li {
  font-family: 'Roboto', sans-serif;
}

.el-menu-item {
  a {
    text-decoration: none;
  }
}

.login-form-container {
  padding: 20px;
  margin: auto;

  @media only screen and (min-width: 600px)  {
    max-width: 600px;
  }
}

.login-form {
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background: #DADADA
}

@media print {
  /* All your print styles go here */
  .el-menu-header {
    display: none !important;
  }

  footer {
    display: none;
  }
}
</style>