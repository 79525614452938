<template>
  <div class="order-page-container">
    <div class="sku-list-wrapper" v-if="offer.content">
      <div class="sku-list-item" :key="index" v-for="item, index in offer.content.skuList">
        <div>
          <img :src="item.image" />
          <span class="sku-list-id">{{item.id}}</span><br/>
        </div>
        <div>
          <span class="rrp">RRP: ${{offer.rrp || item.rrp}}</span> /
          <span class="rrp">Offer: ${{calcOfferPrice(discountRate, offer.rrp || item.rrp)}}</span>
        </div>
        <div class="button-group">
          <el-button size="small" type="success" @click="addItem(item)" :disabled="isInCart(item.id)">Add to cart</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OrderPage',
  props: {
    msg: String
  },
  data() {
    return {
      offerId: '',
      offer: []
    }
  },
  computed: {
    ...mapState([
      'count',
      'orderList',
      'offerData',
      'user'
    ]),
    discountRate() {
      return this.user.discountRate
    }
  },
  watch: {
    offerData() {
      const offerId = this.$route.params.id
      this.offerId = offerId
      this.offer = this.offerData.filter(item => item.offerId === offerId)[0]
    }
  },
  mounted() {
    this.$store.dispatch('getOfferData')
  },
  methods: {
    calcOfferPrice (discountRate, rrp) {
      return  rrp - (parseFloat(discountRate) * rrp).toFixed(2)
    },
    isInCart (sku) {
      return this.orderList.filter(item => item.sku === sku).length > 0
    },
    addItem (item) {
      const { id, image, rrp } = item
      const findMatch = this.orderList.filter(item => item.sku === id)
      const quantity = findMatch.length ? findMatch[0].quantity : 0

      const addedItem = {
        sku: id,
        quantity: quantity + 1,
        offerId: this.offerId,
        image,
        price: this.calcOfferPrice(this.discountRate, this.offer.rrp || rrp),
        rrp: this.offer.rrp || rrp,
        discountRate: this.discountRate
      }

      const newOrderList = this.orderList.filter(item => item.sku !== id)

      const updatedOrderList = [...newOrderList, addedItem]
      this.$store.dispatch('updateCart', { orderList: updatedOrderList })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.sku-list-wrapper {
  display: flex;
  max-width: 1024px;
  flex-flow: row wrap;
  margin: auto;

  .sku-list-item {
    width: 20%;
    flex: 0 0 20%;
    margin: 0 0 20px 0;

    img {
      width: 100%;
    }
  }
}

.rrp {
  font-size: 12px;
}

.sku-list-id {
  font-size: 12px;
  font-weight: bold;
}

#order-page-content {
  font-size: 10px;
}
</style>
