<template>
  <div class="page-container">
    <MenuNav />
    <OrderPage 
        :offerId="offerId"
        msg="Welcome to Your Vue.js App"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OrderPage from '../components/OrderPage.vue'
import MenuNav from '../components/Menu.vue'

export default {
  name: 'OfferPage',
  components: {
    OrderPage,
    MenuNav
  },
  computed: {
    ...mapState([
      'count',
      'orderList'
    ]),
    offerId() {
        return this.$route.params.id
    }
  },
  methods: {
    goToCart() {
      this.$router.push('/cart')
    }
  }
}
</script>

<style>

#order-page-api {
  display: none;
}

.flex-grow {
  flex-grow: 1;
}
</style>
