export const getTotalCart = (orderList) => {
    const subTotal = orderList.reduce((acc, item) => {
        const calcItem = item.quantity * item.price

        acc += calcItem

        return acc
      }, 0)

    const tax = subTotal * 0.1

    const getTotal = parseFloat(subTotal) + parseFloat(tax)

    return getTotal.toFixed(2)
}

export const calcOrderItem = (item) => {
  const calc = (item.price * item.quantity).toFixed(2)

  return `$${calc}`
}

export const calcSubTotal = (orderList) => {
  const subtotal = orderList.reduce((acc, item) => {
    const calcItem = item.quantity * item.price

    acc += calcItem

    return acc
  }, 0)

  const fixedSubTotal = subtotal.toFixed(2)

  return fixedSubTotal
}

export const calcTax = (orderList) => {
  const subTotal = calcSubTotal(orderList)
  return (subTotal * 0.1).toFixed(2)
}


export const getTotalInfo = (currentOrder) => {
  const orderList = JSON.parse(currentOrder.orderList)

  const discountRate = currentOrder.discount / 100
  const totalBeforeDiscount = orderList.reduce((acc, item) => {
    const calcItem = item.quantity * item.price

    acc += calcItem

    return acc
  }, 0)

  const discount = totalBeforeDiscount * discountRate || 0

  const subTotal = totalBeforeDiscount
  const subTotalWithoutTax = totalBeforeDiscount - discount

  const tax = subTotalWithoutTax * 0.1

  const total = parseFloat(subTotalWithoutTax) + parseFloat(tax)

  return {
    discount,
    subTotal,
    subTotalWithoutTax,
    tax,
    freight: 0,
    total
  }
}

export const getDiscount = (discountRate, rrp) => {
  return rrp - (rrp * discountRate)
}

export const getFirebaseDate = (dateCreated) => {
  return typeof dateCreated.toDate === 'function' ? dateCreated.toDate().toDateString() : ''
}