<template>
    <div v-if="orderList.length">
        <h2>Tax invoice</h2>
        <table class="invoice-top">
            <tr>
                <td class="left" width="40%">
                    <strong>Goji Interactive Pty Ltd - Trading Brand: Colordemy</strong><br/>
                    <strong>ABN 75 604 655 825</strong><br/>
                    Address: 24A Mcilvenie St, Canley Heights, NSW 2166<br/>
                    Email: si.nguyen@colordemy.com
                    Website: www.colordemy.com<br/><br/>
                </td>

                <td>Invoice Number<br/><span class="large-text">{{ currentOrder.invoiceNumber }}</span></td>
                <td>Date<br/><strong>{{ getFirebaseDate(currentOrder.dateCreated) }}</strong></td>
            </tr>
            <tr>
                <td class="left" width="40%">
                    <span class="large-text">Billing Address</span><br/>
                    Client: <strong>{{ currentOrder.firstName }} {{ currentOrder.lastName }}</strong><br/>
                    Company: <strong>{{ currentOrder.company }}</strong><br/>
                    Email: {{ currentOrder.email }}<br/>
                    Address: {{ currentOrder.billingAddress || currentOrder.address }}
                </td>
                <td colspan="2" class="left">Title of goods supplied on this invoice remain the property of Goji Interactive Pty Ltd until payment is received in full. Goods not returned within thirty days are deemed accepted for payment and no claims will be recognised after this time. All goods claimed as faulty must be returned to Goji Interactive Pty Ltd by the purchaser and at the purchasers expense.</td>
            </tr>
        </table>
        <table class="invoice">
            <tr>
                <td>No</td>
                <td>Image</td>
                <td>SKU</td>
                <td>Campaign</td>
                <td>Quantity</td>
                <td>RRP</td>
                <td>Discount %</td>
                <td>Price</td>
                <td>Amount</td>
            </tr>
            <tr :key="index" v-for="item, index in orderList">
                <td>{{ index + 1 }}</td>
                <td><img :src="item.image" width="50" /></td>
                <td>{{ item.sku }}</td>
                <td>{{ item.offerId }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.rrp }}</td>

                <td>{{ calcDiscount(item.discountRate) }}</td>
                <td>{{ item.price }}</td>
                <td class="right">{{ calcOrderItem(item) }}</td>
            </tr>
            <tr>
                <td colspan="8" class="table-bottom">Subtotal</td>
                <td class="right table-bottom">${{ getTotalInfo(currentOrder).subTotal }}</td>
            </tr>
            <tr v-if="currentOrder.discount > 0">
                <td colspan="8" class="table-bottom">Discount {{ currentOrder.discount }}%</td>
                <td class="right table-bottom">-${{ getTotalInfo(currentOrder).discount }}</td>
            </tr>            
            <tr v-if="currentOrder.discount > 0">
                <td colspan="8" class="table-bottom">Subtotal without tax</td>
                <td class="right table-bottom">${{ getTotalInfo(currentOrder).subTotalWithoutTax }}</td>
            </tr>
            <tr>
                <td colspan="8" class="table-bottom">GST</td>
                <td class="right table-bottom">${{ getTotalInfo(currentOrder).tax.toFixed(2) }}</td>
            </tr>
            <tr>
                <td colspan="8" class="table-bottom">Freight</td>
                <td class="right table-bottom">${{ getTotalInfo(currentOrder).freight }}</td>
            </tr>
            <tr>
                <td colspan="8" class="table-bottom">Total</td>
                <td class="right table-bottom">${{ getTotalInfo(currentOrder).total.toFixed(2) }}</td>
            </tr>
            <tr>
                <td colspan="8" class="table-bottom"></td>
                <td class="right table-bottom"><span class="payment-status">{{ getStatusText() }}</span></td>
            </tr>
            <tr>
                <td colspan="8" class="table-bottom">Payments</td>
                <td class="right table-bottom"><span class="large-text">{{  getPayments() }}</span></td>
            </tr>
            <tr>
                <td colspan="8" class="table-bottom">Balance due</td>
                <td class="right table-bottom"><span class="large-text">{{  getBalanceDue() }}</span></td>
            </tr>
        </table>
        <br/><br/>
        <table class="invoice-top" width="100%">
            <tr>
                <td class="left" width="50%">
                    <span class="large-text">
                        Delivery Address<br/>
                    </span>
                    <span class="med-text">
                        <strong>{{ currentOrder.firstName }} {{ currentOrder.lastName }}</strong><br/>
                        {{ currentOrder.company }}<br/>
                        {{ currentOrder.deliveryAddress || currentOrder.address}}<br/>
                        <strong>Note:</strong> {{ currentOrder.note }}
                    </span>
                </td>
                <td class="right" width="50%">
                    <span class="med-text" v-if="isPaid">
                        Thank you for your business.<br/><br/>
                    </span>
                    <span class="med-text" v-if="!isPaid">
                        *Invoice must be paid prior to <strong>{{ payBefore() }}</strong><br/><br/>
                    </span>
                    <div v-if="this.currentOrder.status === 'unpaid'">
                        <span class="large-text">
                            Direct Debit<br/>
                        </span>
                        <span class="med-text">
                            Commonwealth Bank: 062-134 <br/>
                            Account No.: 1040 3514<br/>
                            Reference: {{ currentOrder.invoiceNumber }}<br/><br/>
                            Please quote your invoice number as reference and email remittance to si.nguyen@colordemy.com
                        </span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
// import { Timestamp } from "@firebase/firestore";
import { getTotalCart, calcOrderItem, getTotalInfo, getFirebaseDate } from '@/utils/calc'

export default {
  name: 'TaxInvoice',
  props: {
    currentOrder: Object
  },
  data() {
    return {
      orderList: [],
    }
  },
  computed: {
    isPaid() {
        return this.currentOrder.status === 'paid'
    }
  },
  watch: {
    currentOrder() {
        this.orderList = JSON.parse(this.currentOrder.orderList)
    }
  },
  mounted() {
    console.log(this.currentOrder)
    this.orderList = JSON.parse(this.currentOrder.orderList)
  },
  methods: {
    calcOrderItem,
    getTotalCart,
    getTotalInfo,
    getFirebaseDate,
    calcDiscount(discountRate) {
        return `-${(discountRate * 100).toFixed(2)}%`
    },
    getStatusText() {
        const textMap = {
            'open': '',
            'archived': '',
            'unpaid': '** UNPAID **',
            'paid': '** PAID **'
        }

        return textMap[this.currentOrder.status]
    },
    getPayments() {
        if (this.currentOrder.status === 'paid') {
            return `$${this.getTotalInfo(this.currentOrder).total.toFixed(2)}`
        } else if (this.currentOrder.status === 'unpaid') {
            return 0
        } else {
            return 'N/A'
        }
    },
    getBalanceDue() {
        if (this.currentOrder.status === 'paid') {
            return 0
        } else if (this.currentOrder.status === 'unpaid') {
            return `$${this.getTotalInfo(this.currentOrder).total.toFixed(2)}`
        } else {
            return 'N/A'
        }    
    },
    payBefore() {

        const dateCreated = this.currentOrder.dateCreated || undefined

        const ts = dateCreated.seconds * 1000 + (this.currentOrder.expired * 86400000)
        const expiredDate = new Date(ts)
        const expiredDateStr = expiredDate.toDateString()

        return expiredDateStr
    },
    bottomMessage() {
        const payBefore = this.payBefore()
        return this.currentOrder.status === 'paid' ?
            `Thank you for your business.` :
            `*Invoice must be paid prior to <strong>${payBefore}</strong>`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

table.invoice,
th,
td {
    border: 1px solid #333;
    border-collapse: collapse;
    font-size: 10px;
    word-break: normal;
}

table.invoice-top {
    td, td {
        border: 0;
        border-collapse: collapse;
        font-size: 10px;
        word-break: normal;
        font-size: 12px;
    }
}

td.left {
    text-align: left;
}

td.right {
    text-align: right;
}

.invoice {
    width: 100%;
    margin: 5px 0;
}

.table-bottom {
    text-align: right;
    font-size: 14px;
}

.payment-status {
    font-size: 14px;
    font-weight: bold;
}

.large-text {
    font-weight: bold;
    font-size: 20px;
}

.med-text {
    font-size: 14px;
}
</style>