<template>
    <div class="page-container" v-if="Object.keys(currentOrder).length">
        <TaxInvoice :currentOrder="currentOrder"/>
    </div>
</template>
  
<script>
import { mapState } from 'vuex'
import TaxInvoice from '../components/TaxInvoice'

export default {
    name: 'AdOrderTaxInvoice',
    components: {
        TaxInvoice
    },
    computed: {
        ...mapState([
            'orderList',
            'currentOrder'
        ])
    },
    mounted() {
        const documentId = this.$route.params.id

        this.$store.dispatch('FIREBASE_GET_ORDERS', { documentId })
    },
    methods: {

    }
}
</script>

<style>

</style>
  