<template>
    <div class="page-container">
        <h1>Member List</h1>
        <div>
            <input type="text" v-model="email" placeholder="Email"/><br/>
            <input type="password" v-model="password" placeholder="Password"/><br/>
            <input type="submit" name="Submit" @click="onHandleSubmit"/>
        </div>
    </div>
</template>
  
<script>
import { mapState } from 'vuex'

export default {
    name: 'AdMembers',
    data() {
        return {
            email: '',
            password: ''
        }
    },
    components: {
    },
    computed: {
        ...mapState([
            'orderList',
            'currentOrder'
        ])
    },
    mounted() {
        // const documentId = this.$route.params.id

    },
    methods: {
        onHandleSubmit() {
            this.$store.dispatch('FIREBASE_CREATE_USER', {
                email: this.email,
                password: this.password
            })
        }
    }
}
</script>

<style>

</style>
  