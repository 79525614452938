import { createApp } from 'vue'
import { createStore } from 'vuex'
import ElementPlus from 'element-plus'
import * as VueRouter from 'vue-router'
import createPersistedState from 'vuex-persistedstate'

import 'element-plus/dist/index.css'
import MainPage from './pages/MainPage.vue'

import HomePage from './pages/HomePage.vue'
import CartPage from './pages/CartPage.vue'
import UserPage from './pages/UserPage.vue'
import OfferPage from './pages/OfferPage.vue'
import AdOrderRequestsPage from './pages/AdOrderRequestsPage.vue'
import AdViewOffer from './pages/AdViewOffer.vue'
import AdOrderTaxInvoice from './pages/AdOrderTaxInvoice.vue'
import AdMembers from './pages/AdMembers.vue'

import { state } from './store'
import { mutations } from './store/mutations'
import { actions } from './store/actions'
import Cookies from 'js-cookie'

const routes = [
  { path: '/', component: HomePage },
  { path: '/cart', component: CartPage },
  { path: '/user', component: UserPage },
  { path: '/offer/:id', component: OfferPage },
  { path: '/ad-view-offer/:id', component: AdViewOffer },
  { path: '/order-requests', component: AdOrderRequestsPage },
  { path: '/order-invoice/:id', component: AdOrderTaxInvoice },
  { path: '/members', component: AdMembers }

]

const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: VueRouter.createWebHashHistory(),
  routes, // short for `routes: routes`
})

const store = createStore({
    state: state(),
    mutations,
    actions,
    plugins: [createPersistedState({
      getItem: (key) => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, { expires: 1, secure: true }),
      removeItem: (key) => Cookies.remove(key),
    })]
  })

const app = createApp(MainPage)
app.use(router)

app.use(ElementPlus)
app.use(store)
app.mount('#order-page-app')
