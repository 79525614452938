import firebase from '../firebase.config'
const db = firebase.firestore()

export default {
  async FIREBASE_AUTH ({ commit }, data) {
    await firebase.auth().signInWithEmailAndPassword(data.email, data.password).catch(() => {
    //   const errorCode = error.code
    //   const errorMessage = error.message

    //   console.error(errorCode, errorMessage)
    }).then(result => {
        result.user.getIdToken().then(token => {

          var usersRef = db.collection("users");
          var uid = result.user.uid

          // Create a query to find the document with population equal to 1000000
          var query = usersRef.where("uid", "==", uid);

          query.get().then(function(querySnapshot) {
            if (querySnapshot.size > 0) {
                querySnapshot.forEach(function(doc) {
                    const data = doc.data()

                    console.log(data)

                    const userRole = data.role
                    commit('receiveFirebaseAuth', {
                      role: userRole,
                      token,
                      user: data
                    })

                });
            } else {
                console.log("No matching documents.");
            }
          }).catch(function(error) {
              console.log("Error getting documents:", error);
          });
        })
      })
      .catch(() => {
        commit('receiveFirebaseAuthError')
      })
  },
  async FIREBASE_GET_USER ({ commit }) {
    await firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          user.getIdToken().then(result => {
            commit('receiveFirebaseAuth', result)
          })
        }
      }
    )
  },
  async FIREBASE_SIGN_OUT ({ commit }) {
    await firebase.auth().signOut().then((result) => {
      commit('receiveFirebaseSignOut', result)
    })
  },
  async FIREBASE_CREATE_USER ({ commit }, data) {
    const { email, password } = data
    await firebase.auth().createUserWithEmailAndPassword(email, password)
      .then((user) => {
        console.log(user)
        commit('receiveFirebaseCreateUser')
        // loginUserSuccess(dispatch, user)
      })
      .catch(() => {
        // createUserFail(dispatch)
      })
  }
}