import axios from 'axios'

import { getTotalCart } from './calc'
const prodEndpoint = 'https://us-central1-doodlink-971db.cloudfunctions.net/mail'
const devEndpoint = 'http://localhost:5001/doodlink-971db/us-central1/mail'

const getEndpoint = (path) => {
  const isDev = window.location.hostname.indexOf('localhost') > -1
  return isDev ? `${devEndpoint}${path}` : `${prodEndpoint}${path}`
}

export const sendHtmlMail = (sendInfo, bodyHtml) => {
    const mailEndpoint = getEndpoint('/send')
    const { email, subject } = sendInfo

    return axios.post(mailEndpoint, {
        toEmail: `${email}`,
        title: `${subject}`,
        body: bodyHtml
    })
}

export const sendInvoicePdf = (sendInfo, bodyHtml, pdfOutput) => {
    const mailEndpoint = getEndpoint('/sendPdf')
    const { email, subject, fileName } = sendInfo

    return axios.post(mailEndpoint, {
        toEmail: `${email}`,
        title: `${subject}`,
        body: bodyHtml,
        attachments: [
            {
                content: pdfOutput,
                filename: fileName,
                type: 'application/pdf',
                disposition: 'attachment'
            }
        ]
    })
}

export const getOrderRequestHtml = (orderList, clientInfo) => {
    const { firstName, lastName, email, address, website, phone } = clientInfo

    const orderListHtml = orderList.reduce((acc, item) => {
        const total = item.quantity * item.price
        acc += `
            <tr width="100%">
                <td width="100px"><img src="${item.image}" width="50px" /></td>
                <td width="500px">${item.sku} -  $${item.price} x${item.quantity} --- $${total}<br/>
                ${item.offerId}
                </td>
            </tr>`

        return acc;
    }, '')

    const orderDate = new Date()

    return `<div>
        <h2>Order Request</h2>
        First Name: ${firstName}<br/>
        Last Name: ${lastName}<br/>
        Email: ${email}<br/>
        Phone: ${phone}<br/>
        Website: ${website}<br/>
        Address: ${address}<br/>
        Order Date: ${orderDate}
        <table border="0" width="600px"><tr><td width="100%">
            <h2>Order form</h2>
            <table>${orderListHtml}</table>
            <div>Total: $${getTotalCart(orderList)}</div>
        </td></tr></table></div>`
}

