import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

// import { initializeApp } from 'firebase/app'

// import 'firebase/firestore'
// import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyDcxP0AIQ6hOrxednrhNeHYCkETDb2KZhg",
    authDomain: "colordemy-customers.firebaseapp.com",
    projectId: "colordemy-customers",
    storageBucket: "colordemy-customers.appspot.com",
    messagingSenderId: "816204467876",
    appId: "1:816204467876:web:d4e22302cea39330772031",
    measurementId: "G-1GY1FD09MF"
  };

  
// const firebaseConfig = {
//     apiKey: 'AIzaSyACiguGpeXarS5vJp2q8_PBA_y22ud8TfY',
//     authDomain: 'doodlink-971db.firebaseapp.com',
//     databaseURL: 'https://doodlink-971db.firebaseio.com',
//     projectId: 'doodlink-971db',
//     storageBucket: 'doodlink-971db.appspot.com',
//     messagingSenderId: '268680113921',
//     appId: '1:268680113921:web:50b4c51fcd34c0ebe4f500',
//     measurementId: 'G-7MD6BXWVZF'
//   }
  
// const app = initializeApp(firebaseConfig);

// export default app


export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()