const increment = (state) => {
    state.count++
}

const updateCart = (state, data) => {
    state.orderList = data.orderList.sort((a, b) => {
        const textA = a.sku.toUpperCase();
        const textB = b.sku.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
     })
}

const receiveClearCart = (state) => {
    state.orderList = []
    state.currentOrder = {}
}

const receiveUpdateCartForEdit = (state, data) => {
    state.currentOrder = data
    state.orderList = JSON.parse(data.orderList)
}

const updateSkuList = (state, data) => {
    state.skuData = Object.assign({}, state.skuData, data)
}

const updateOfferData = (state, data) => {
    state.offerData = data
}

const receiveCreateOrder = (state) => {
    state.orderList = []
}

const receiveUpdateOrder = (state) => {
    state.message = {
        content: 'Order has been updated!'
    }

    state.currentOrder = {}
    state.orderList = []
}

const receiveFirebaseAuth = (state, { token, role, user}) => {
    state.isLoggedIn = true
    state.accessToken = token
    state.role = role
    state.isAuthError = false
    state.user = user
}

const receiveFirebaseAuthError = (state) => {
    state.isLoggedIn = false
    state.isAuthError = true
}

const receiveFirebaseSignOut = (state, token) => {
    state.isLoggedIn = false
    state.isAuthError = false

    state.accessToken = token
}

const receiveFirebaseCreateUser = (state) => {
    console.log(state)
}

const receiveOrder = (state, data) => {
    state.currentOrder = data
}

const receiveAllOrders = (state, data) => {
    state.allOrders = data
}

const receiveOrderRemoved = (state) => {
    state.message = {
        content: 'Order has been removed!'
    }
}

export const mutations = {
    increment,
    updateCart,
    receiveOrder,
    receiveClearCart,
    receiveUpdateOrder,
    updateSkuList,
    updateOfferData,
    receiveCreateOrder,
    receiveFirebaseAuth,
    receiveFirebaseSignOut,
    receiveFirebaseAuthError,
    receiveFirebaseCreateUser,
    receiveAllOrders,
    receiveOrderRemoved,
    receiveUpdateCartForEdit
}