<template>
  <h1>Registration form</h1>
  <el-alert type="error" v-if="this.showError" title="Form submission cannot be sent, please contact admin" />
  <div class="register-form-container" v-loading="loading">
    <el-form ref="registerForm" :model="form" label-width="120px" label-position="top" class="demo-ruleForm"
      size="default" :rules="rules()" status-icon>
      <el-form-item label="First name" prop="firstName">
        <el-input v-model="form.firstName" />
      </el-form-item>
      <el-form-item label="Last name" prop="lastName">
        <el-input v-model="form.lastName"/>
      </el-form-item>
      <el-form-item prop="email" label="Email" :rules="[
        {
          required: true,
          message: 'Please input email address',
          trigger: 'blur',
        },
        {
          type: 'email',
          message: 'Please input correct email address',
          trigger: ['blur', 'change'],
        },
      ]">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-form-item label="Phone Number" prop="phone">
        <el-input v-model="form.phone" />
      </el-form-item>
      <el-form-item label="Website" prop="website">
        <el-input v-model="form.website" />
      </el-form-item>
      <el-form-item label="Company" prop="company">
        <el-input v-model="form.company" />
      </el-form-item>
      <el-form-item label="Billing Address" prop="billingAddress">
        <el-input v-model="form.billingAddress" />
      </el-form-item>
      <el-form-item label="Delivery Address" prop="deliveryAddress">
        <el-input v-model="form.deliveryAddress" />
      </el-form-item>
      <el-form-item label="Date Created" prop="dateCreated">

        <el-date-picker
          v-model="form.dateCreated"
          type="date"
          placeholder="Date Created">
        </el-date-picker>

      </el-form-item>
      <el-form-item label="Expired" prop="dateCreated">
        <el-input v-model="form.expired" />
      </el-form-item>
      <el-form-item label="Note" prop="note">
        <el-input v-model="form.note" />
      </el-form-item>

      <el-form-item label="" prop="agreed">
        <el-checkbox v-model="form.agreed" label="I understand my order minimum is $240" />
      </el-form-item>

      <el-form-item label="Status" prop="status" v-if="isAdmin">
        <el-select v-model="form.status" class="m-2" placeholder="Select" size="small">
            <el-option
              v-for="item in statusOptions"
              :key="item"
              :label="item"
              :value="item"
            />
        </el-select>
        
      </el-form-item>
      <el-form-item label="Discount (%)" prop="discount" v-if="isAdmin">
        <el-input v-model="form.discount" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleSubmit()">
          {{ isEdit ? `Update your order` : `Send your order` }}
        </el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import { mapState } from "vuex"

import { getOrderRequestHtml } from '../utils/mail'
import { calcOrderItem } from '../utils/calc'
import { invoiceNumber } from '@/utils/common'

const statusOptions = [
  'open',
  'archived',
  'unpaid',
  'paid'
]

export default {
  name: "FormRegister",
  props: {
    currentOrder: Object
  },
  data() {
    return {
      loading: false,
      showError: false,
      statusOptions,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        dateCreated: "",
        billingAddress: "",
        deliveryAddress: "",
        website: "",
        agreed: false,
        note: "",
        discount: 0,
        status: ''
      },
      bodyHtml: ''
    };
  },
  computed: {
    ...mapState([
      "orderList", 
      "offerData",
      "role"
    ]),
    isAdmin() {
      return this.role === 'admin'
    },
    isEdit() {
      return this.currentOrder && this.currentOrder.id
    }
  },
  watch: {
    currentOrder() {
      this.feedContent()
    }
  },
  mounted() {
    this.$store.dispatch("getOfferData")
    this.showError = false
    
    if (this.isEdit) {
      this.feedContent()
    }
  },
  methods: {
    invoiceNumber,
    calcOrderItem,
    feedContent() {
      this.form = Object.assign({}, this.form, {
        firstName: this.currentOrder.firstName || '',
        lastName: this.currentOrder.lastName || '',
        email: this.currentOrder.email || '',
        phone: this.currentOrder.phone || '',
        company: this.currentOrder.company || '',
        billingAddress: this.currentOrder.billingAddress || '',
        deliveryAddress: this.currentOrder.deliveryAddress || '',
        website: this.currentOrder.website || '',
        agreed: this.currentOrder.agreed,
        note: this.currentOrder.note || '',
        dateCreated: this.currentOrder.dateCreated.toDate() || '',
        modifiedDate: this.currentOrder.modifiedDate?.toDate() || '',
        expired: this.currentOrder.expired || 14,
        status: this.currentOrder.status,
        invoiceNumber: this.currentOrder.invoiceNumber || this.getInvoiceNumber(),
        orderList: JSON.stringify(this.orderList),
        discount: this.currentOrder.discount || 0
      })
    },
    calcDiscount (discountRate) {
      return `-${discountRate * 100}%`
    },
    handleSubmit () {
      const el = this.$refs.registerForm

      el.validate(valid => {
        if (!valid && !this.isAdmin) {
          return false
        } else {
          const clientInfo = this.form

          const bodyHtml = getOrderRequestHtml(this.orderList, clientInfo)
          this.bodyHtml = bodyHtml
          const dateCreated = new Date()

          if (this.isEdit) {
            this.$store.dispatch('FIREBASE_UPDATE_ORDERS', {
              formData: Object.assign({}, this.form, {
                modifiedDate: dateCreated
              }),
              documentId: this.currentOrder.id
            })
          } else {
            this.$store.dispatch('FIREBASE_CREATE_ORDERS', {
              ...this.form,
              dateCreated,
              modifiedDate: dateCreated,
              orderList: JSON.stringify(this.orderList),
              invoiceNumber: this.getInvoiceNumber()
            })

            this.$parent.handleShowSuccess()
          }


          return true
        }
      })
    },
    getInvoiceNumber() {
      return this.invoiceNumber()
    },
    isAccepted(rule, value, callback) {
      if (!value) {
        callback(new Error(`Please agree to minimum order`))
      } else {
        callback()
      }
    },
    isRequired(rule, value, callback) {
      const mapText = {
        'firstName': 'first name',
        'lastName': 'last name',
        'website': 'website',
        'address': 'address'
      }
      if (value === '') {
        callback(new Error(`Please input ${mapText[rule.field]}`))
      } else {
        callback()
      }
    },
    rules () {
      return {
        firstName: [{ validator: this.isRequired, trigger: 'blur', required: true }],
        lastName: [{ validator: this.isRequired, trigger: 'blur', required: true }],
        email: [{ validator: this.isRequired, trigger: 'blur', required: true }],
        address: [{ validator: this.isRequired, trigger: 'blur', required: true }],
        agreed: [{ validator: this.isAccepted, trigger: 'blur', required: true }]
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.register-form-container {
  width: 100%;
  margin: 20px auto;
  padding: 0;
  border-radius: 10px;

  @media only screen and (min-width: 600px)  {
    background: #DADADA;
  }

  form {
    padding: 20px;
  }
}

.el-form-item {
  display: flex;
  flex-direction: column;
}

</style>