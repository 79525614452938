<template>
  <div class="current-offers-container">
    <h1>Current Offers</h1>
    <div class="offer-group">
        <div class="offer-item" v-for="item, index in offerData" :key="index">
            <div class="offer-thumbs" v-if="item.content.skuList">
                <div class="thumb-item">
                    <div class="thumb-photo" :src="photo.image" :key="photoIndex" v-for="photo, photoIndex in item.content.skuList">
                        <img :src="photo.image" />
                    </div>
                </div>
            </div>
            <div class="offer-info" v-if="item.content.skuList">
                <h3>{{item.name}} ({{item.content.skuList.length}} designs)</h3>
                <div>{{item.description}}</div>
                <div>Expired: {{item.expiredBy}}</div>
                <div>MOQ: {{item.minimumOrder}} pcs</div>
                <div>Recommended Retail Price: {{item.rrp}} AUD/pcs</div>
                <div>Discount rate: {{ (discountRate * 100).toFixed(2) }}%</div>
                <div v-if="item.rrp">Price: <strong>{{getDiscount(discountRate, item.rrp)}} AUD/pcs + GST</strong></div>
                <div>Note: {{ item.note }}</div>
                <div v-if="isAdmin"><el-button type="default" size="small" @click="viewOffer(item.offerId)">Admin: View Offer</el-button></div>
            </div>
            <div class="button-group" v-if="item.content.skuList">
                <el-button type="default" size="large" @click="addAllToCart(item)" :disabled="!isAllInCart(item)">Add all to cart</el-button><br/>
                <el-button type="success" size="large" @click="gotoOffer(item.offerId)">View products</el-button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CurrentOffersPage',
  props: {
    msg: String
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState([
      'orderList',
      'offerData',
      'role',
      'user'
    ]),
    isAdmin() {
        return this.role === 'admin'
    },
    discountRate() {
        return this.user.discountRate
    }
  },
  watch: {
    offerData() {
        console.log(this.offerData)
    }
  },
  mounted() {
        this.$store.dispatch('getOfferData')
  },
  methods: {
    getDiscount(discount, rrp) {
        return (rrp - (rrp * discount)).toFixed(2)
    },
    gotoOffer(id) {
        const route = {
            path: `/offer/${id}`,
        }

        this.$router.push(route)
    },
    addAllToCart(offer) {
        const discountRate = offer.discountRate
        const rrp = offer.rrp
        const offerId = offer.offerId
        offer.content.skuList.forEach(item => {
            this.addItem(item, discountRate, rrp, offerId)
        })
    },
    isAllInCart(offer) {
        return offer.content.skuList.reduce((acc, listItem) => {
            const isInCart = this.orderList.filter(item => item.sku === listItem.id).length > 0
            
            acc = isInCart ? acc : acc + 1

            return acc
        }, 0) > 0
    },
    addItem (item, discountRate, rrp, offerId) {
      const { id, image } = item
      const findMatch = this.orderList.filter(item => item.sku === id)
      const quantity = findMatch.length ? findMatch[0].quantity : 0

      const addedItem = {
        sku: id,
        quantity: quantity + 1,
        offerId: offerId,
        image,
        price: this.getDiscount(discountRate, rrp),
        rrp,
        discountRate
      }

      const newOrderList = this.orderList.filter(item => item.sku !== id)

      const updatedOrderList = [...newOrderList, addedItem]
      this.$store.dispatch('updateCart', { orderList: updatedOrderList })
    },
    viewOffer(offerId) {
        console.log(offerId)
        this.$router.push(`/ad-view-offer/${offerId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.offer-group {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;

    .offer-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        justify-content: left;
        margin: 10px 0;
        border-bottom: 1px solid #DDD;

        @media only screen and (min-width: 600px)  {
            width: 100%;
            flex-direction: row;
            border-radius: 5px;
            padding: 10px;
            border: 1px solid #DDD;
        }
    }
}


.thumb-item {
    width: 100%;
    margin: 0;

    @media only screen and (min-width: 600px)  {
        width: 200px;
    }

    .thumb-photo {
        width: 50px;
        float: left;

        img {
            width: 100%;
        }
    }
}

.offer-info {
    display: flex;
    justify-content: left;
    flex-direction: column;
    flex: 0 0 100%;
    padding: 0;
    margin: 0;
    width: 100%;

    @media only screen and (min-width: 600px)  {
        padding: 20px;
        flex: 0 0 50%;
        width: 50%;
    }

    div {
        text-align: left;
        padding: 5px 0;
    }

    h3 {
        text-align: left;
    }
}

.button-group {
    display: flex;
    flex-direction: row;
    margin: auto;

    button {
        margin: 10px;
    }
}

</style>
