import firebaseApp from '../firebase.config'
const db = firebaseApp.firestore()

const COLLECTION_ORDERS = 'orderRequests'

export default {
  async FIREBASE_GET_ALL_ORDERS ({ commit }) {
    await db.collection(COLLECTION_ORDERS).get()
      .then((querySnapshot) => {
        const collections = []

        querySnapshot.forEach((doc) => {
          const id = doc.ref.id
          const itemData = Object.assign({}, doc.data(), { id })
          collections.push(itemData)
        })

        commit('receiveAllOrders', collections)
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
  },
  async FIREBASE_GET_ORDERS({ commit }, data) {
    console.log('RE')
    await db.collection(COLLECTION_ORDERS).doc(data.documentId).get()
      .then((doc) => {
        if (doc.exists) {
          console.log('Document data:', doc.data())
          commit('receiveOrder', doc.data())
        } else {
          console.log('No such document!')
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
  },
  async FIREBASE_CREATE_ORDERS ({ commit }, data) {
    await db.collection(COLLECTION_ORDERS).add(data)
      .then((docRef) => {
        console.log('Document written with ID: ', docRef.id)
        commit('receiveCreateOrder', docRef.id)
      })
      .catch((error) => {
        console.error('Error adding document: ', error)
      })
  },
  async FIREBASE_UPDATE_ORDERS ({ commit }, data) {
    await db.collection(COLLECTION_ORDERS).doc(data.documentId)
      .set(data.formData)
      .then(() => {
        commit('receiveUpdateOrder')
      })
      .catch((error) => {
        console.error('Error adding document: ', error)
      })
  },
  async FIREBASE_REMOVE_ORDER ({ commit }, data) {
    await db.collection(COLLECTION_ORDERS).doc(data.documentId).delete()
      .then(() => {
        commit('receiveOrderRemoved')

        this.dispatch('FIREBASE_GET_ALL_ORDERS')
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
  }
}