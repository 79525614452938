export const invoiceNumber = () => {
    const newDate = new Date()
    const gdate = newDate.getDate()
    const month = newDate.getMonth() + 1
    const hours = newDate.getHours()
    const minutes = newDate.getMinutes()
    const seconds = newDate.getSeconds()

    const addZero = (number) => {
        return number < 10 ? `0${number}` : number
    }

    const invoiceNumber = `INV${addZero(gdate)}${addZero(month)}${addZero(hours)}${addZero(minutes)}${addZero(seconds)}`

    return invoiceNumber
}