<template>
  <div class="menu-container"></div>
</template>

<script>
export default {
  name: 'MenuNav',
  props: {},
  data() {
    return {
      skuList: [],
      title: '',
      rrp: 0,
      discountRate: 0,
      offerPrice: 0,
      orderList: {}
    }
  },
  watch: {

  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
